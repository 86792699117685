
import { Component, Mixins } from 'vue-property-decorator';
import { Franchise } from 'client-website-ts-library/types';
import { API, Config } from 'client-website-ts-library/services';
import { View } from 'client-website-ts-library/plugins';
import Form from '@/components/Forms/Form.vue';

@Component({
  components: {
    Form,
  },
})
export default class ContactUs extends Mixins(View) {
  private franchise: Franchise | null = null;

  mounted() {
    API.Franchises.Get(Config.Website.Settings!.WebsiteId).then((franchise) => {
      this.franchise = franchise;
    });
  }
}
